@charset "UTF-8";
@font-face {
  font-family: 'Lato';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url(/assets/css/fonts/lato.bd03a2cc277bbbc338d464e679fe9942.woff2) format("woff2"), url(/assets/css/fonts/lato.27bd77b9162d388cb8d4c4217c7c5e2a.woff) format("woff"); }

@font-face {
  font-family: 'Lato';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url(/assets/css/fonts/lato-bold.cccb897485813c7c256901dbca54ecf2.woff2) format("woff2"), url(/assets/css/fonts/lato-bold.d878b6c29b10beca227e9eef4246111b.woff) format("woff"); }

@font-face {
  font-family: 'unoauto';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url(/assets/css/fonts/unoauto.715eed0ffea325634435be22eb3162d3.woff2) format("woff2"), url(/assets/css/fonts/unoauto.1dffaddaf3685f3c2307da37f1712669.woff) format("woff"); }

[class^="icon-"], [class*=" icon-"] {
  font-family: 'unoauto';
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon {
  font-family: 'Lato' !important; }

.icon:before {
  font-family: 'unoauto'; }

.icon-velocimetro:before {
  content: "\E900"; }

.icon-cerrar::before {
  content: "\E901"; }

.icon-berlina:before, .icon-berlinas:before {
  content: "\E902"; }

.icon-cubo:before {
  content: "\E903"; }

.icon-tic-cuadro-vacio:before {
  content: "\E904"; }

.icon-camara:before {
  content: "\E905"; }

.icon-automatico:before {
  content: "\E906"; }

.icon-manual:before {
  content: "\E907"; }

.icon-carrito:before {
  content: "\E908"; }

.icon-tic-circulo-lleno:before {
  content: "\E909"; }

.icon-tic-circulo-vacio:before {
  content: "\E90A"; }

.icon-deportivo:before, .icon-deportivos:before {
  content: "\E90B"; }

.icon-descapotable:before, .icon-descapotables:before {
  content: "\E90C"; }

.icon-diesel:before {
  content: "\E90D"; }

.icon-dimensiones:before {
  content: "\E90E"; }

.icon-electrico:before {
  content: "\E90F"; }

.icon-embudo:before {
  content: "\E910"; }

.icon-emisiones:before {
  content: "\E911"; }

.icon-excl:before {
  content: "\E912"; }

.icon-familiar:before, .icon-familiares:before {
  content: "\E913"; }

.icon-flecha-abajo:before {
  content: "\E914"; }

.icon-flecha-arriba:before {
  content: "\E915"; }

.icon-flecha-der:before {
  content: "\E916"; }

.icon-flecha-izq:before {
  content: "\E917"; }

.icon-gas:before {
  content: "\E918"; }

.icon-gasolina:before {
  content: "\E919"; }

.icon-goma:before {
  content: "\E91A"; }

.icon-hibrido:before {
  content: "\E91B"; }

.icon-info:before {
  content: "\E91C"; }

.icon-menu::before {
  content: "\E91D"; }

.icon-monovolumen:before, .icon-monovolumenes:before {
  content: "\E91E"; }

.icon-papelera:before {
  content: "\E91F"; }

.icon-usuario:before {
  content: "\E920"; }

.icon-puerta:before {
  content: "\E921"; }

.icon-sobre::before {
  content: "\E922"; }

.icon-combustible:before {
  content: "\E923"; }

.icon-suv:before, .icon-todoterrenos:before {
  content: "\E924"; }

.icon-telefono:before {
  content: "\E925"; }

.icon-tic-cuadro-lleno:before {
  content: "\E926"; }

.icon-tic:before {
  content: "\E927"; }

.icon-ubicacion:before {
  content: "\E928"; }

.icon-pequeno:before, .icon-compactos:before {
  content: "\E929"; }

.icon-industrial:before {
  content: "\E92A"; }

.icon-circulo:before {
  content: "\E92B"; }

.icon-circulo_check:before {
  content: "\E92C"; }

.icon-mano:before {
  content: "\E92D"; }

.icon-cama_oferta:before {
  content: "\E92E"; }

.icon-like-abajo:before {
  content: "\E92F"; }

.icon-like-arriba:before {
  content: "\E930"; }

.icon-etiqueta:before {
  content: "\E931"; }

.icon-oro:before {
  content: "\E932"; }

.icon-reloj:before {
  content: "\E933"; }

* {
  margin: 0;
  padding: 0;
  font-weight: 400;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  /*.rem(font-size, 10);*/
  font-size: 16px;
  font-size: 1.6rem;
  font-family: 'Lato'; }

*:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

html {
  font-size: 62.5%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  color: #212121; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block; }

ul li {
  list-style: none; }

a {
  color: #1976d2;
  cursor: pointer;
  text-decoration: none;
  background: transparent;
  outline: 0; }
  a:hover, a:focus {
    color: #448aff;
    text-decoration: none;
    outline: 0; }
  a:active, a:focus {
    outline: 0;
    background: transparent; }

img {
  border: 0;
  vertical-align: middle; }

img:focus {
  border: none; }

h1 {
  /*.rem(font-size, 10);*/
  font-size: 32px;
  font-size: 3.2rem; }

h2 {
  /*.rem(font-size, 10);*/
  font-size: 28px;
  font-size: 2.8rem; }

h3, h4, h5, h6 {
  /*.rem(font-size, 10);*/
  font-size: 16px;
  font-size: 1.6rem; }

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }
  button[disabled], html input[type="button"][disabled], input[type="reset"][disabled], input[type="submit"][disabled] {
    cursor: default; }

textarea {
  overflow: auto; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

:focus {
  outline: 0;
  border: none; }

.hidden {
  display: none !important; }

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-nh1g-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important; }

.modal {
  display: none;
  overflow: scroll;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  outline: 0;
  background: rgba(0, 0, 0, 0.8); }
  .modal .modal-dialog {
    /*.rem(font-size, 10);*/
    width: 600px;
    width: 60rem;
    margin: auto;
    /*.rem(font-size, 10);*/
    margin-top: 50px;
    margin-top: 5rem;
    background: #fff;
    border-radius: 6px;
    position: relative; }
  .modal .cerrar {
    color: #fff;
    position: absolute;
    /*.rem(font-size, 10);*/
    top: -30px;
    top: -3rem;
    /*.rem(font-size, 10);*/
    right: 10px;
    right: 1rem; }

.modal .contenedor, .contactar .contenedor {
  /*.rem(margin, 10, 10);*/
  padding: 20px 40px;
  padding: 2rem 4rem; }
  .modal .contenedor .titulo, .contactar .contenedor .titulo {
    float: left;
    width: 100%;
    border-bottom: 1px solid #e7ebef;
    /*.rem(font-size, 10);*/
    padding-bottom: 20px;
    padding-bottom: 2rem;
    /*.rem(font-size, 10);*/
    margin-bottom: 20px;
    margin-bottom: 2rem; }
    .modal .contenedor .titulo p, .contactar .contenedor .titulo p {
      float: left;
      /*.rem(font-size, 10);*/
      font-size: 20px;
      font-size: 2rem;
      width: 78%; }
    .modal .contenedor .titulo span, .contactar .contenedor .titulo span {
      display: block;
      /*.rem(font-size, 10);*/
      font-size: 28px;
      font-size: 2.8rem;
      color: #ef6c00; }
    .modal .contenedor .titulo a, .contactar .contenedor .titulo a {
      floa: right; }
  .modal .contenedor .datos, .contactar .contenedor .datos {
    margin: auto;
    /*.rem(font-size, 10);*/
    width: 235px;
    width: 23.5rem; }
    .modal .contenedor .datos .selectFiltro, .contactar .contenedor .datos .selectFiltro {
      /*.rem(font-size, 10);*/
      margin-bottom: 15px;
      margin-bottom: 1.5rem; }
    .modal .contenedor .datos input[type="text"], .contactar .contenedor .datos input[type="text"] {
      /*.rem(font-size, 10);*/
      margin-bottom: 15px;
      margin-bottom: 1.5rem;
      /*.rem(font-size, 10);*/
      width: 235px;
      width: 23.5rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      /*.rem(font-size, 10);*/
      padding-right: 35px;
      padding-right: 3.5rem; }
    .modal .contenedor .datos textarea, .contactar .contenedor .datos textarea {
      /*.rem(font-size, 10);*/
      margin-bottom: 15px;
      margin-bottom: 1.5rem;
      /*.rem(font-size, 10);*/
      height: 80px;
      height: 8rem; }
    .modal .contenedor .datos .form-group, .contactar .contenedor .datos .form-group {
      display: inline-block;
      position: relative; }
      .modal .contenedor .datos .form-group span, .contactar .contenedor .datos .form-group span {
        position: absolute;
        /*.rem(font-size, 10);*/
        top: 7px;
        top: 0.7rem;
        /*.rem(font-size, 10);*/
        right: 10px;
        right: 1rem;
        color: #b6b6b6;
        /*.rem(font-size, 10);*/
        font-size: 20px;
        font-size: 2rem; }
      .modal .contenedor .datos .form-group.privacidad, .contactar .contenedor .datos .form-group.privacidad {
        float: none;
        margin: 0;
        /*.rem(font-size, 10);*/
        margin-bottom: 30px;
        margin-bottom: 3rem;
        width: 100%; }
        .modal .contenedor .datos .form-group.privacidad input, .contactar .contenedor .datos .form-group.privacidad input {
          position: relative;
          /*.rem(font-size, 10);*/
          top: 3px;
          top: 0.3rem; }
        .modal .contenedor .datos .form-group.privacidad > p, .contactar .contenedor .datos .form-group.privacidad > p {
          /*.rem(font-size, 10);*/
          top: 42px;
          top: 4.2rem; }
    .modal .contenedor .datos p, .contactar .contenedor .datos p {
      text-align: center; }
      .modal .contenedor .datos p span, .contactar .contenedor .datos p span {
        display: block;
        /*.rem(font-size, 10);*/
        font-size: 32px;
        font-size: 3.2rem; }
    .modal .contenedor .datos .selectFiltro p, .contactar .contenedor .datos .selectFiltro p {
      text-align: left; }
    .modal .contenedor .datos.success, .contactar .contenedor .datos.success {
      clear: both;
      width: 100%;
      background: #dff0d8;
      border: 1px solid #468847;
      /*.rem(margin, 10, 10);*/
      padding: 5px 10px;
      padding: 0.5rem 1rem;
      /*.rem(font-size, 10);*/
      padding-bottom: 30px;
      padding-bottom: 3rem; }
      .modal .contenedor .datos.success p span, .contactar .contenedor .datos.success p span {
        /*.rem(font-size, 10);*/
        font-size: 28px;
        font-size: 2.8rem;
        /*.rem(margin, 10, 10);*/
        margin: 10px 0px;
        margin: 1rem 0rem; }
      .modal .contenedor .datos.success .icon, .contactar .contenedor .datos.success .icon {
        font-weight: 700;
        color: #468847; }
      .modal .contenedor .datos.success a, .contactar .contenedor .datos.success a {
        display: block;
        text-align: center; }
  .modal .contenedor .btn, .contactar .contenedor .btn {
    float: left;
    display: block;
    /*.rem(font-size, 10);*/
    width: 235px;
    width: 23.5rem;
    margin: auto;
    /*.rem(font-size, 10);*/
    margin-bottom: 15px;
    margin-bottom: 1.5rem; }
  .modal .contenedor .legal, .contactar .contenedor .legal {
    /*.rem(font-size, 10);*/
    margin-bottom: 20px;
    margin-bottom: 2rem;
    color: #727272;
    font-size: 14px !important;
    display: inline-block;
    text-align: left !important; }
    .modal .contenedor .legal a, .contactar .contenedor .legal a {
      /*.rem(font-size, 10);*/
      font-size: 14px;
      font-size: 1.4rem; }

.modal.active, .contactar.active {
  display: block; }

input[type="text"], textarea {
  display: block;
  /*.rem(font-size, 10);*/
  height: 35px;
  height: 3.5rem;
  /*.rem(margin, 10, 10);*/
  padding: 6px 12px;
  padding: 0.6rem 1.2rem;
  color: #4d4d4d;
  border: 1px solid #b6b6b6;
  border-radius: 3px;
  /*.rem(font-size, 10);*/
  width: 235px;
  width: 23.5rem; }

.error input[type="text"] {
  border-color: #b74b4b;
  /*.rem(font-size, 10);*/
  margin-bottom: 30px;
  margin-bottom: 3rem; }

.error > p:not(.legal) {
  color: #b74b4b;
  text-align: left;
  /*.rem(font-size, 10);*/
  font-size: 12px;
  font-size: 1.2rem;
  position: absolute;
  /*.rem(font-size, 10);*/
  top: 35px;
  top: 3.5rem;
  left: 0; }

*::-webkit-input-placeholder {
  color: #b6b6b6; }

*:-moz-placeholder {
  color: #b6b6b6; }

*::-moz-placeholder {
  color: #b6b6b6; }

*:-ms-input-placeholder {
  color: #b6b6b6; }

.checkbox, .radio {
  position: relative;
  float: left;
  width: 100%; }
  .checkbox input, .radio input {
    display: none; }
  .checkbox label, .radio label {
    display: block;
    cursor: pointer;
    width: auto;
    padding: 0;
    /*.rem(font-size, 10);*/
    padding-top: 5px;
    padding-top: 0.5rem;
    /*.rem(font-size, 10);*/
    padding-left: 35px;
    padding-left: 3.5rem;
    color: #4d4d4d;
    /*.rem(font-size, 10);*/
    min-height: 32px;
    min-height: 3.2rem; }
    .checkbox label .icon:before, .radio label .icon:before {
      content: "\E904";
      position: absolute;
      top: 0;
      left: 0;
      /*.rem(font-size, 10);*/
      font-size: 28px;
      font-size: 2.8rem;
      color: #4d4d4d !important; }
    .checkbox label input:checked + .icon:before, .radio label input:checked + .icon:before {
      content: "\E926"; }

.radio label .icon:before {
  content: "\E90A"; }

.radio label input:checked + .icon:before {
  content: "\E909"; }

.selectFiltro {
  float: left;
  position: relative;
  background: #fff; }
  .selectFiltro p {
    /*.rem(font-size, 10);*/
    width: 235px;
    width: 23.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /*.rem(font-size, 10);*/
    height: 37px;
    height: 3.7rem;
    border: 1px solid #b6b6b6;
    border-radius: 3px;
    background: url(/assets/img/flecha.d846dd0f59f11fc450e06cec5b703a84.png) no-repeat 207px 15px;
    cursor: pointer;
    color: #4d4d4d;
    /*.rem(margin, 10, 10);*/
    padding: 8px 35px;
    padding: 0.8rem 3.5rem;
    padding-bottom: 0;
    /*.rem(font-size, 10);*/
    padding-left: 5px;
    padding-left: 0.5rem; }
  .selectFiltro ul {
    display: none;
    /*.rem(font-size, 10);*/
    width: 235px;
    width: 23.5rem;
    position: absolute;
    /*.rem(font-size, 10);*/
    top: 35px;
    top: 3.5rem;
    left: 0;
    z-index: 999;
    /*.rem(font-size, 10);*/
    padding-left: 5px;
    padding-left: 0.5rem;
    border: 1px solid #b6b6b6;
    border-top: none;
    background: #fff;
    border-radius: 0 0 3px 3px;
    /*.rem(font-size, 10);*/
    max-height: 250px;
    max-height: 25rem;
    overflow-y: scroll;
    overflow-x: hidden; }
    .selectFiltro ul li {
      /*.rem(font-size, 10);*/
      width: 215px;
      width: 21.5rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      color: #4d4d4d;
      /*.rem(margin, 10, 10);*/
      padding: 5px 0px;
      padding: 0.5rem 0rem; }
      .selectFiltro ul li:last-child {
        /*.rem(font-size, 10);*/
        margin-bottom: 5px;
        margin-bottom: 0.5rem; }
  .selectFiltro.disabled p {
    background-color: #e7ebef;
    color: #b6b6b6;
    cursor: default; }
  .selectFiltro.disabled ul {
    display: none !important; }
  .selectFiltro.seleccionado p {
    border: 1px solid #212121;
    color: #212121; }
  .selectFiltro.error p {
    border: 1px solid #b74b4b;
    color: #b74b4b;
    background-color: #f2dede; }

.btn {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  touch-action: manipulation;
  /*.rem(font-size, 10);*/
  height: 37px;
  height: 3.7rem;
  border-radius: 3px;
  /*.rem(margin, 10, 10);*/
  padding: 8px 10px;
  padding: 0.8rem 1rem;
  padding-bottom: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #fff;
  /*.rem(font-size, 10);*/
  font-size: 16px;
  font-size: 1.6rem; }
  .btn:hover {
    color: #fff;
    text-decoration: none; }
  .btn.btn-orange {
    background: #ef6c00;
    border: 1px solid #e65100;
    color: #fff; }
    .btn.btn-orange:hover {
      background: #e65100; }
  .btn.btn-blue {
    background: #1976d2;
    border: 1px solid #1976d2;
    color: #fff; }
    .btn.btn-blue:hover {
      background: #448aff;
      border: 1px solid #448aff; }
  .btn.disabled, .btn.disabled:hover {
    background: #b6b6b6;
    border-color: #b6b6b6; }

.container, .container-fluid {
  margin: auto; }

.container {
  position: relative; }
  @media (min-width: 1279px) {
    .container {
      /*.rem(font-size, 10);*/
      width: 1223px;
      width: 122.3rem; } }
  @media (min-width: 1023px) and (max-width: 1279px) {
    .container {
      /*.rem(font-size, 10);*/
      width: 967px;
      width: 96.7rem; } }
  @media (max-width: 1023px) {
    .container {
      /*.rem(font-size, 10);*/
      width: 728px;
      width: 72.8rem; } }

.publicidad {
  float: left;
  /*.rem(font-size, 10);*/
  margin-bottom: 20px;
  margin-bottom: 2rem; }

.text-publi {
  float: left;
  width: 100%;
  background: #e7ebef;
  text-align: center;
  /*.rem(font-size, 10);*/
  font-size: 10px;
  font-size: 1rem; }

.col-izq {
  float: left; }
  @media (min-width: 1279px) {
    .col-izq {
      /*.rem(font-size, 10);*/
      width: 893px;
      width: 89.3rem; } }
  @media (min-width: 1023px) and (max-width: 1279px) {
    .col-izq {
      /*.rem(font-size, 10);*/
      width: 637px;
      width: 63.7rem; } }
  @media (max-width: 1023px) {
    .col-izq {
      width: 100%; } }
  .col-izq h2 {
    float: left;
    width: 84%;
    color: #727272;
    /*.rem(font-size, 10);*/
    margin-bottom: 10px;
    margin-bottom: 1rem;
    /*.rem(font-size, 10);*/
    font-size: 20px;
    font-size: 2rem;
    /*.rem(font-size, 10);*/
    min-height: 50px;
    min-height: 5rem; }
    .col-izq h2 a {
      display: inline-block;
      /*.rem(font-size, 10);*/
      font-size: 24px;
      font-size: 2.4rem;
      font-weight: 700;
      text-decoration: underline;
      white-space: nowrap;
      /*.rem(font-size, 10);*/
      max-width: 190px;
      max-width: 19rem; }
      .col-izq h2 a:before {
        float: right;
        content: "\E914";
        font-family: unoauto;
        /*.rem(font-size, 10);*/
        font-size: 16px;
        font-size: 1.6rem;
        margin: 0;
        /*.rem(font-size, 10);*/
        margin-top: 7px;
        margin-top: 0.7rem;
        /*.rem(font-size, 10);*/
        margin-left: 5px;
        margin-left: 0.5rem; }

.col-der {
  float: right;
  /*.rem(font-size, 10);*/
  width: 300px;
  width: 30rem; }
  @media (max-width: 1023px) {
    .col-der {
      display: none; } }

.navbar {
  /*.rem(font-size, 10);*/
  height: 60px;
  height: 6rem;
  background: #fff;
  border-bottom: 1px solid #1976d2; }
  .navbar .logo {
    position: relative;
    /*.rem(font-size, 10);*/
    top: 12px;
    top: 1.2rem;
    left: 0;
    /*.rem(font-size, 10);*/
    width: 180px;
    width: 18rem;
    height: auto; }
  .navbar .enlaces {
    float: right;
    /*.rem(margin, 10, 10);*/
    margin: 20px 0px;
    margin: 2rem 0rem; }
    .navbar .enlaces a {
      color: #1976d2;
      /*.rem(font-size, 10);*/
      margin-left: 30px;
      margin-left: 3rem; }
      @media (max-width: 1023px) {
        .navbar .enlaces a {
          /*.rem(font-size, 10);*/
          margin-left: 15px;
          margin-left: 1.5rem; }
          .navbar .enlaces a:nth-of-type(1) {
            margin-left: 0; } }

.breadcrumb {
  float: left;
  width: 100%;
  /*.rem(margin, 10, 10);*/
  margin: 20px 0px;
  margin: 2rem 0rem;
  /*.rem(font-size, 10);*/
  font-size: 14px;
  font-size: 1.4rem;
  color: #4d4d4d; }
  .breadcrumb a {
    /*.rem(font-size, 10);*/
    font-size: 14px;
    font-size: 1.4rem; }

.cookie {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #b6b6b6;
  z-index: 99999;
  /*.rem(font-size, 10);*/
  font-size: 14px;
  font-size: 1.4rem;
  /*.rem(margin, 10, 10);*/
  padding: 5px 50px;
  padding: 0.5rem 5rem;
  /*.rem(font-size, 10);*/
  padding-left: 10px;
  padding-left: 1rem; }
  .cookie a {
    /*.rem(font-size, 10);*/
    font-size: 14px;
    font-size: 1.4rem; }
  .cookie .cerrar {
    position: absolute;
    /*.rem(font-size, 10);*/
    top: 5px;
    top: 0.5rem;
    /*.rem(font-size, 10);*/
    right: 10px;
    right: 1rem;
    /*.rem(font-size, 10);*/
    font-size: 20px;
    font-size: 2rem;
    font-weight: 700; }

footer {
  background: #4d4d4d;
  float: left;
  width: 100%;
  /*.rem(margin, 10, 10);*/
  padding: 20px 0px;
  padding: 2rem 0rem;
  color: #fff; }
  footer ul {
    float: left;
    width: 25%; }
    footer ul li {
      float: left;
      width: 100%;
      /*.rem(font-size, 10);*/
      margin-bottom: 10px;
      margin-bottom: 1rem;
      /*.rem(font-size, 10);*/
      font-size: 14px;
      font-size: 1.4rem; }
      footer ul li span {
        display: block;
        float: left;
        width: 100%; }
      footer ul li a {
        color: #fff;
        opacity: 0.7;
        /*.rem(font-size, 10);*/
        font-size: 14px;
        font-size: 1.4rem; }
        footer ul li a:hover {
          color: #fff;
          opacity: 1; }
      footer ul li p {
        /*.rem(font-size, 10);*/
        font-size: 12px;
        font-size: 1.2rem;
        text-align: center;
        margin: 0;
        /*.rem(font-size, 10);*/
        line-height: 12px;
        line-height: 1.2rem; }
      footer ul li img {
        display: block;
        /*.rem(font-size, 10);*/
        width: 150px;
        width: 15rem;
        height: auto;
        margin: auto;
        /*.rem(font-size, 10);*/
        margin-bottom: 5px;
        margin-bottom: 0.5rem; }
    footer ul:nth-of-type(4) li:nth-of-type(1) {
      /*.rem(font-size, 10);*/
      margin-bottom: 50px;
      margin-bottom: 5rem; }
    footer ul:nth-of-type(5) {
      width: 100%;
      border-top: 1px solid rgba(255, 255, 255, 0.7);
      /*.rem(font-size, 10);*/
      padding-top: 30px;
      padding-top: 3rem;
      /*.rem(font-size, 10);*/
      margin-top: 20px;
      margin-top: 2rem; }
      footer ul:nth-of-type(5) img {
        /*.rem(font-size, 10);*/
        width: 91px;
        width: 9.1rem;
        /*.rem(font-size, 10);*/
        margin-bottom: 20px;
        margin-bottom: 2rem; }
      footer ul:nth-of-type(5) p {
        width: 100%;
        opacity: 0.7; }

#modalSelectFiltro ul {
  position: relative;
  /*.rem(font-size, 10);*/
  margin-top: 60px;
  margin-top: 6rem; }
  #modalSelectFiltro ul li {
    display: inline-block;
    /*.rem(font-size, 10);*/
    margin-bottom: 10px;
    margin-bottom: 1rem;
    /*.rem(font-size, 10);*/
    width: 165px;
    width: 16.5rem;
    vertical-align: top; }
    #modalSelectFiltro ul li img {
      /*.rem(font-size, 10);*/
      margin-right: 10px;
      margin-right: 1rem;
      /*.rem(font-size, 10);*/
      width: 30px;
      width: 3rem; }
    #modalSelectFiltro ul li .icon {
      display: block;
      color: #4d4d4d;
      /*.rem(font-size, 10);*/
      font-size: 50px;
      font-size: 5rem;
      width: 100%;
      position: absolute;
      /*.rem(font-size, 10);*/
      top: -20px;
      top: -2rem;
      left: auto; }
    #modalSelectFiltro ul li:nth-of-type(1) {
      position: absolute;
      /*.rem(font-size, 10);*/
      top: -60px;
      top: -6rem;
      left: 0;
      display: block;
      text-align: center;
      border: 1px solid #1976d2;
      border-radius: 3px;
      /*.rem(margin, 10, 10);*/
      padding: 8px 0px;
      padding: 0.8rem 0rem;
      /*.rem(font-size, 10);*/
      width: 150px;
      width: 15rem; }
  #modalSelectFiltro ul.carroceria li:nth-of-type(n+2) {
    text-align: center;
    position: relative;
    /*.rem(font-size, 10);*/
    padding-top: 25px;
    padding-top: 2.5rem;
    /*.rem(font-size, 10);*/
    margin-bottom: 40px;
    margin-bottom: 4rem; }

#modalSelectFiltro ul:not(.carroceria) {
  column-count: 3;
  -webkit-column-count: 3;
  -moz-column-count: 3; }

#covid-modal img {
  margin: auto;
  /*.rem(font-size, 10);*/
  margin-bottom: 20px;
  margin-bottom: 2rem;
  display: block;
  /*.rem(font-size, 10);*/
  width: 199px;
  width: 19.9rem; }

#covid-modal h2 {
  float: none;
  /*.rem(font-size, 10);*/
  margin-bottom: 20px;
  margin-bottom: 2rem;
  text-align: center; }

#covid-modal p {
  text-align: center;
  /*.rem(margin, 10, 10);*/
  padding: 0px 20px;
  padding: 0rem 2rem;
  /*.rem(font-size, 10);*/
  margin-bottom: 20px;
  margin-bottom: 2rem; }
  #covid-modal p span {
    font-weight: 700; }
    #covid-modal p span:nth-of-type(1) {
      color: #1976d2;
      display: block;
      /*.rem(font-size, 10);*/
      margin-bottom: 10px;
      margin-bottom: 1rem;
      /*.rem(font-size, 10);*/
      font-size: 20px;
      font-size: 2rem; }

#covid-modal .btn {
  float: none;
  margin: auto;
  display: block;
  /*.rem(font-size, 10);*/
  width: 240px;
  width: 24rem; }

.texto-seo, .enlaces-seo {
  float: left;
  width: 100%;
  background: #fff;
  border: 1px solid #fff;
  /*.rem(font-size, 10);*/
  margin-bottom: 20px;
  margin-bottom: 2rem;
  /*.rem(font-size, 10);*/
  padding: 20px;
  padding: 2rem;
  padding-bottom: 0; }
  .texto-seo h3, .texto-seo p, .enlaces-seo h3, .enlaces-seo p {
    float: left;
    width: 100%;
    text-align: center; }
  .texto-seo h3, .enlaces-seo h3 {
    /*.rem(font-size, 10);*/
    margin-bottom: 20px;
    margin-bottom: 2rem;
    /*.rem(font-size, 10);*/
    font-size: 18px;
    font-size: 1.8rem;
    font-weight: 700; }
  .texto-seo p, .enlaces-seo p {
    /*.rem(font-size, 10);*/
    line-height: 28px;
    line-height: 2.8rem;
    /*.rem(font-size, 10);*/
    padding-bottom: 20px;
    padding-bottom: 2rem; }
    .texto-seo p span, .enlaces-seo p span {
      font-weight: 700; }
  .texto-seo ul, .enlaces-seo ul {
    float: left;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    justify-content: center; }
    .texto-seo ul li, .enlaces-seo ul li {
      float: left;
      /*.rem(font-size, 10);*/
      margin-right: 10px;
      margin-right: 1rem;
      /*.rem(font-size, 10);*/
      margin-bottom: 20px;
      margin-bottom: 2rem; }
      .texto-seo ul li a, .enlaces-seo ul li a {
        float: left;
        /*.rem(margin, 10, 10);*/
        padding: 5px 10px;
        padding: 0.5rem 1rem;
        border: 1px solid #b6b6b6;
        border-radius: 3px;
        color: #1976d2; }
        .texto-seo ul li a:hover, .enlaces-seo ul li a:hover {
          background: #1976d2;
          border: 1px solid #1976d2;
          color: #fff; }
      .texto-seo ul li:nth-of-type(n+31):not(.ver-mas), .enlaces-seo ul li:nth-of-type(n+31):not(.ver-mas) {
        display: none; }
      .texto-seo ul li:nth-of-type(-n+31).ver-mas, .enlaces-seo ul li:nth-of-type(-n+31).ver-mas {
        display: none; }
      .texto-seo ul li.ver-mas, .enlaces-seo ul li.ver-mas {
        display: block; }
        .texto-seo ul li.ver-mas a, .enlaces-seo ul li.ver-mas a {
          border: 1px solid #1976d2;
          position: relative;
          /*.rem(font-size, 10);*/
          padding-left: 35px;
          padding-left: 3.5rem; }
          .texto-seo ul li.ver-mas a:before, .enlaces-seo ul li.ver-mas a:before {
            content: "+";
            position: absolute;
            /*.rem(font-size, 10);*/
            top: 5px;
            top: 0.5rem;
            /*.rem(font-size, 10);*/
            left: 9px;
            left: 0.9rem;
            background: #1976d2;
            border-radius: 20px;
            color: #fff;
            /*.rem(margin, 10, 10);*/
            padding: 0px 5px;
            padding: 0rem 0.5rem; }
          .texto-seo ul li.ver-mas a:hover:before, .enlaces-seo ul li.ver-mas a:hover:before {
            background: #fff;
            color: #1976d2; }
      @media (max-width: 1023px) {
        .texto-seo ul li:nth-of-type(n+16):not(.ver-mas), .enlaces-seo ul li:nth-of-type(n+16):not(.ver-mas) {
          display: none; }
        .texto-seo ul li:nth-of-type(-n+16).ver-mas, .enlaces-seo ul li:nth-of-type(-n+16).ver-mas {
          display: none; } }

.ficha .texto-seo, .ficha .enlaces-seo {
  border: 1px solid #e7ebef; }

.banner-publicidad {
  float: left;
  width: 100%;
  background: #e7ebef;
  /*.rem(font-size, 10);*/
  padding-top: 10px;
  padding-top: 1rem;
  /*.rem(font-size, 10);*/
  padding-bottom: 20px;
  padding-bottom: 2rem; }
  .banner-publicidad a {
    display: block;
    /*.rem(font-size, 10);*/
    width: 722px;
    width: 72.2rem;
    margin: auto; }
  .banner-publicidad p {
    float: left;
    width: 50%;
    /*.rem(font-size, 10);*/
    margin-bottom: 24px;
    margin-bottom: 2.4rem;
    /*.rem(font-size, 10);*/
    font-size: 24px;
    font-size: 2.4rem;
    color: #4d4d4d;
    font-weight: 700; }
    .banner-publicidad p span {
      display: block;
      float: left;
      width: 100%;
      /*.rem(font-size, 10);*/
      margin-top: 4px;
      margin-top: 0.4rem;
      /*.rem(font-size, 10);*/
      font-size: 18px;
      font-size: 1.8rem;
      font-weight: 400; }
  .banner-publicidad .btn {
    float: left;
    /*.rem(font-size, 10);*/
    width: 400px;
    width: 40rem; }
  .banner-publicidad img {
    float: right;
    width: auto;
    /*.rem(font-size, 10);*/
    height: 116px;
    height: 11.6rem; }
  .listado .banner-publicidad, .ficha .banner-publicidad {
    background: #fff;
    /*.rem(font-size, 10);*/
    padding: 30px;
    padding: 3rem;
    /*.rem(font-size, 10);*/
    margin-bottom: 30px;
    margin-bottom: 3rem; }
    .listado .banner-publicidad a, .ficha .banner-publicidad a {
      width: 100%; }
  .ficha .banner-publicidad {
    border: 1px solid #d6d6d6;
    /*.rem(margin, 10, 10);*/
    padding: 20px 30px;
    padding: 2rem 3rem; }

/* Capa Cookies */
#didomi-host {
  font-family: 'Lato' !important; }
  #didomi-host .didomi-exterior-border {
    border-color: #1976d2 !important; }
  #didomi-host .didomi-popup-container {
    border: none !important; }
  #didomi-host .didomi-popup-notice .didomi-popup-notice-text p {
    font-size: 14px !important;
    line-height: 160%; }
  #didomi-host .didomi-popup-notice .didomi-notice-view-partners-link-in-text {
    color: #1976d2 !important;
    text-decoration: none !important; }
  #didomi-host .didomi-popup-notice.didomi-popup-notice-with-data-processing {
    /*.rem(font-size, 10);*/
    padding-top: 20px;
    padding-top: 2rem; }
    #didomi-host .didomi-popup-notice.didomi-popup-notice-with-data-processing:before {
      display: block;
      content: url(/assets/css/fonts/logo.f93b5bdd867e17f58bb5b46cda3d04da.svg);
      /*.rem(font-size, 10);*/
      margin-top: -15px;
      margin-top: -1.5rem;
      /*.rem(font-size, 10);*/
      margin-bottom: 20px;
      margin-bottom: 2rem;
      /*.rem(font-size, 10);*/
      width: 200px;
      width: 20rem;
      /*.rem(font-size, 10);*/
      height: 36px;
      height: 3.6rem; }
  #didomi-host .didomi-notice-data-processing-container {
    text-align: left !important;
    font-size: 14px !important; }
    #didomi-host .didomi-notice-data-processing-container p {
      line-height: 150% !important; }
    #didomi-host .didomi-notice-data-processing-container .didomi-notice-data-processing-list {
      font-weight: 400 !important;
      color: #4d4d4d !important; }
  #didomi-host .didomi-popup-notice-optin-type .didomi-popup-notice-buttons .didomi-components-button {
    background: #fff !important;
    color: #1976d2 !important; }
    #didomi-host .didomi-popup-notice-optin-type .didomi-popup-notice-buttons .didomi-components-button.didomi-button.didomi-dismiss-button.didomi-components-button--color.didomi-button-highlight.highlight-button {
      color: #fff !important;
      background: #1976d2 !important; }
  #didomi-host .didomi-mobile #didomi-popup .didomi-popup-notice p {
    text-align: left !important; }
  #didomi-host .didomi-mobile .didomi-notice-data-processing-container .didomi-notice-data-processing-title, #didomi-host .didomi-mobile .didomi-notice-data-processing-container .didomi-notice-data-processing-list {
    font-size: 12px !important;
    line-height: 150%; }
  #didomi-host .didomi-popup-notice .didomi-popup-notice-text, #didomi-host .didomi-popup-notice .didomi-popup-notice-subtext {
    max-width: 100% !important; }

h1, h2, h3 {
  /*.rem(font-size, 10);*/
  font-size: 32px;
  font-size: 3.2rem; }
  @media (max-width: 1023px) {
    h1, h2, h3 {
      /*.rem(font-size, 10);*/
      font-size: 24px;
      font-size: 2.4rem; } }

.navbar {
  background: none;
  border: none;
  position: relative;
  height: auto; }
  .navbar .container > a {
    position: absolute;
    top: 0;
    /*.rem(font-size, 10);*/
    left: 0px;
    left: 0rem;
    z-index: 999; }
  .navbar .container .enlaces {
    position: absolute;
    top: 0;
    /*.rem(font-size, 10);*/
    right: 0px;
    right: 0rem;
    z-index: 999; }
    .navbar .container .enlaces a {
      color: #fff; }

.img-portada {
  position: relative; }
  .img-portada img {
    width: 100%;
    height: auto; }
  @media (min-width: 1023px) {
    .img-portada .container {
      /*.rem(font-size, 10);*/
      width: 780px;
      width: 78rem; } }

.buscador {
  position: absolute;
  left: 0;
  z-index: 999; }
  @media (min-width: 1279px) {
    .buscador {
      /*.rem(font-size, 10);*/
      top: -280px;
      top: -28rem; } }
  @media (min-width: 1023px) and (max-width: 1279px) {
    .buscador {
      /*.rem(font-size, 10);*/
      top: -210px;
      top: -21rem; } }
  @media (max-width: 1023px) {
    .buscador {
      /*.rem(font-size, 10);*/
      top: -170px;
      top: -17rem; } }
  .buscador h1 {
    color: #fff;
    /*.rem(font-size, 10);*/
    margin-bottom: 20px;
    margin-bottom: 2rem;
    text-align: center; }
  .buscador > ul li {
    /*.rem(font-size, 10);*/
    width: 230px;
    width: 23rem;
    background: rgba(25, 118, 210, 0.6);
    text-align: center;
    display: inline-block;
    /*.rem(margin, 10, 10);*/
    padding: 10px 20px;
    padding: 1rem 2rem; }
    .buscador > ul li a {
      color: #fff; }
    .buscador > ul li.active {
      background: #fff; }
      .buscador > ul li.active a {
        color: #212121; }
  .buscador .pestana {
    display: none;
    background: #fff;
    /*.rem(font-size, 10);*/
    padding: 10px;
    padding: 1rem;
    /*.rem(font-size, 10);*/
    min-height: 58px;
    min-height: 5.8rem; }
    .buscador .pestana .selectFiltro {
      display: block;
      /*.rem(font-size, 10);*/
      width: 247px;
      width: 24.7rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      /*.rem(margin, 10, 10);*/
      padding: 8px 5px;
      padding: 0.8rem 0.5rem;
      color: #4d4d4d;
      /*.rem(font-size, 10);*/
      margin-right: 15px;
      margin-right: 1.5rem;
      border: 1px solid #b6b6b6;
      border-radius: 3px; }
      @media (min-width: 1023px) {
        .buscador .pestana .selectFiltro:hover {
          color: #4d4d4d; } }
      .buscador .pestana .selectFiltro.disabled {
        background-color: #e7ebef;
        color: #b6b6b6;
        cursor: default; }
    .buscador .pestana .radio {
      width: auto;
      /*.rem(margin, 10, 10);*/
      margin: 4px 20px;
      margin: 0.4rem 2rem;
      /*.rem(font-size, 10);*/
      margin-bottom: 10px;
      margin-bottom: 1rem;
      margin-left: 0; }
    .buscador .pestana .form-group {
      display: inline-block;
      /*.rem(font-size, 10);*/
      margin-right: 20px;
      margin-right: 2rem; }
      .buscador .pestana .form-group input {
        /*.rem(font-size, 10);*/
        width: 187px;
        width: 18.7rem;
        /*.rem(font-size, 10);*/
        height: 37px;
        height: 3.7rem; }
    .buscador .pestana .btn {
      float: right;
      /*.rem(font-size, 10);*/
      padding-left: 34px;
      padding-left: 3.4rem;
      /*.rem(font-size, 10);*/
      padding-right: 34px;
      padding-right: 3.4rem; }
    .buscador .pestana.active {
      display: block; }
    .buscador .pestana:nth-of-type(3) .selectFiltro {
      /*.rem(font-size, 10);*/
      width: 509px;
      width: 50.9rem; }
  @media (min-width: 1023px) {
    .buscador > ul li {
      /*.rem(font-size, 10);*/
      margin-right: 4px;
      margin-right: 0.4rem; }
    .buscador .pestana {
      /*.rem(font-size, 10);*/
      width: 780px;
      width: 78rem; } }
  @media (max-width: 1023px) {
    .buscador > ul li {
      /*.rem(font-size, 10);*/
      margin-right: 2px;
      margin-right: 0.2rem; }
    .buscador .pestana .selectFiltro {
      /*.rem(font-size, 10);*/
      width: 235px;
      width: 23.5rem; }
    .buscador .pestana .btn {
      /*.rem(font-size, 10);*/
      padding-left: 20px;
      padding-left: 2rem;
      /*.rem(font-size, 10);*/
      padding-right: 20px;
      padding-right: 2rem; }
    .buscador .pestana:nth-of-type(3) .selectFiltro {
      /*.rem(font-size, 10);*/
      width: 480px;
      width: 48rem; } }

.grupo {
  /*.rem(font-size, 10);*/
  margin-bottom: 40px;
  margin-bottom: 4rem; }
  .grupo h2, .grupo h3 {
    float: left;
    width: 100%;
    /*.rem(font-size, 10);*/
    padding-bottom: 30px;
    padding-bottom: 3rem;
    color: #4d4d4d;
    text-align: center; }
  .grupo .btn {
    float: none;
    display: block;
    /*.rem(font-size, 10);*/
    width: 500px;
    width: 50rem;
    margin: auto; }
  .grupo ul li {
    display: inline-block;
    text-align: center;
    /*.rem(font-size, 10);*/
    margin-bottom: 40px;
    margin-bottom: 4rem; }
    .grupo ul li a {
      display: inline-block;
      margin: auto; }
    .grupo ul li .icon {
      /*.rem(font-size, 10);*/
      font-size: 70px;
      font-size: 7rem;
      display: block;
      /*.rem(font-size, 10);*/
      margin-bottom: 15px;
      margin-bottom: 1.5rem; }
  .grupo.precio {
    background: #e7ebef;
    /*.rem(margin, 10, 10);*/
    padding: 40px 0px;
    padding: 4rem 0rem; }
    .grupo.precio li {
      width: 32%;
      position: relative; }
      @media (min-width: 1279px) {
        .grupo.precio li {
          /*.rem(margin, 10, 10);*/
          padding: 0px 60px;
          padding: 0rem 6rem; } }
      @media (min-width: 1023px) and (max-width: 1279px) {
        .grupo.precio li {
          /*.rem(margin, 10, 10);*/
          padding: 0px 35px;
          padding: 0rem 3.5rem; } }
      @media (max-width: 1023px) {
        .grupo.precio li {
          /*.rem(margin, 10, 10);*/
          padding: 0px 25px;
          padding: 0rem 2.5rem; } }
      .grupo.precio li img {
        display: block;
        margin: auto;
        /*.rem(font-size, 10);*/
        margin-bottom: 20px;
        margin-bottom: 2rem; }
      .grupo.precio li span {
        font-weight: 700; }
        .grupo.precio li span.num {
          color: #b6b6b6;
          position: absolute;
          /*.rem(font-size, 10);*/
          top: 5px;
          top: 0.5rem;
          /*.rem(font-size, 10);*/
          left: 50px;
          left: 5rem;
          /*.rem(font-size, 10);*/
          font-size: 36px;
          font-size: 3.6rem;
          font-weight: 400; }
          @media (max-width: 1023px) {
            .grupo.precio li span.num {
              /*.rem(font-size, 10);*/
              left: 20px;
              left: 2rem; } }
  .grupo.marca, .grupo.provincia {
    /*.rem(font-size, 10);*/
    min-height: 464px;
    min-height: 46.4rem; }
    .grupo.marca ul, .grupo.provincia ul {
      float: left;
      width: 100%;
      column-count: 5;
      -webkit-column-count: 5;
      -moz-column-count: 5;
      /*.rem(font-size, 10);*/
      margin-bottom: 30px;
      margin-bottom: 3rem; }
      .grupo.marca ul li, .grupo.provincia ul li {
        display: block;
        text-align: left;
        /*.rem(font-size, 10);*/
        margin-bottom: 10px;
        margin-bottom: 1rem; }
  .grupo.carroceria ul li {
    width: 24%;
    /*.rem(font-size, 10);*/
    margin-bottom: 30px;
    margin-bottom: 3rem; }
    .grupo.carroceria ul li > a {
      font-weight: 700; }
      .grupo.carroceria ul li > a .icon {
        color: #4d4d4d;
        margin: 0; }
    .grupo.carroceria ul li p {
      color: #727272;
      /*.rem(font-size, 10);*/
      font-size: 14px;
      font-size: 1.4rem; }
      .grupo.carroceria ul li p a {
        display: block; }
      .grupo.carroceria ul li p:before {
        content: "\2014";
        display: block;
        color: #ef6c00;
        /*.rem(font-size, 10);*/
        font-size: 28px;
        font-size: 2.8rem;
        font-weight: 700; }
  .grupo.combustible {
    background: #1976d2;
    /*.rem(font-size, 10);*/
    padding-top: 40px;
    padding-top: 4rem; }
    .grupo.combustible h3, .grupo.combustible a, .grupo.combustible .icon {
      color: #fff; }
    .grupo.combustible ul li {
      width: 19%; }
  .grupo.cambio ul {
    /*.rem(font-size, 10);*/
    width: 700px;
    width: 70rem;
    margin: auto; }
    .grupo.cambio ul li {
      width: 49%; }
  .grupo.texto-seo {
    /*.rem(font-size, 10);*/
    padding: 40px;
    padding: 4rem;
    /*.rem(font-size, 10);*/
    padding-bottom: 20px;
    padding-bottom: 2rem; }
    .grupo.texto-seo h3 {
      /*.rem(font-size, 10);*/
      font-size: 32px;
      font-size: 3.2rem;
      padding-bottom: 0; }

.publicidad {
  float: none;
  /*.rem(font-size, 10);*/
  margin-top: 20px;
  margin-top: 2rem;
  /*.rem(font-size, 10);*/
  margin-bottom: 2px;
  margin-bottom: 0.2rem; }
  .publicidad > img {
    float: left; }
  .publicidad > div {
    display: inline-block;
    width: 23%;
    text-align: center; }
